.home-main-container{
    padding-bottom: 0 ;
    .home-header{
    padding:2.75rem 40px ;
    @include break-point(md){
        padding:  1rem;
    }

    }
 @include break-point(md){
    padding:1rem 0rem !important;
    padding-bottom: 5rem !important;
    margin-bottom: 0;
   
 }
 .service-container{
    @include break-point(md){
      width: calc(100vw - 2rem);
    }
    .service-head{
        .service-heading{
            @include break-point(md){
                font-size: 14px;
            }
        }
    }
    .service-foot{
        @include break-point(md){
           display: block;
        }
    }
    .service-booking-button{
        @include break-point(md){
           display: none !important;
        }
       
    }
 }
 .form-legend{
    @include break-point(md){
        font-size: 14px ;
    }
}
  
    .home-main{
        padding-bottom: 1.5rem;
        @include break-point(md){
            padding: 0 1rem;
        }
        overflow: scroll;
        h1{
            line-height: 3rem;
        }
        .booking-main{
            padding-bottom: 2rem;
            margin-top: 2.6rem;
            @include break-point(md){
                gap: 0;
            margin-top: 0;
                margin-bottom: 4rem;
                flex-direction: column;    
        }
        .date-slot-container{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            padding: 2.6rem 1.5rem;
            padding-bottom: 0;
            @include break-point(md){
                flex-direction: column;
                padding: 0;
                box-shadow: none;
                border: none !important;
            }
            .slot-selector-main,.date-selector-main{
                .slot-picker-wrapper{
                    @include break-point(md){
                        gap: 0.9rem !important;
                        padding-bottom: 16px ;
                    }
                }
                .slot-picker{
                    width: 12rem;
                    @include break-point(md){
                        width: 100%;
                    }
                }
                .heading{
                    margin: 1.5rem 0;
                    @include break-point(md){
                        margin-top: 0 ;
                    }
                }
            }
        }
    }
    }
   
    .radio-selector{
        .radio-main{
            width: calc(50% - 0.5rem);
            padding: 0.8rem 2.3rem;
            @include break-point(md){
                width: 100%;
            }
        }
        @include break-point(md){
            flex-direction: column;
            align-items: center;
        }
       
    }
   
}
.pay-now{
    width: calc(100%);
    display: none;
    z-index: 10;
    button{
        padding: .75rem 1.5rem;
        margin: .75rem;
    }
    @include break-point(md){
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
    }
}
.home-footer{
    position: absolute ;
    bottom: 0 ;
    width: 100vw;
    @include break-point(md){
        position: relative;
        margin-top: 0;
        padding: 0 20px;
        .f-s-15{
            font-size: 10px;
        }
    }
}
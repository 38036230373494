$blue-211:#211551;
$purple-E3D:#E3DCFF;
$grey-F4F:#F4F4F4;
$grey-D9D:#D9D9D9;
$grey-F9F:#F9F9F9;
$grey-D8D:#D8D8D8;
$grey-808:#808080;
$grey-EEE:#EEEEEE;
$white:white;
$black:black;
$grey:grey;
$red:red;
$red-CE2:#CE2D2D;
$red-FFE:#FFE0DC;
$green-0C8:#0C8020;
$green-DDF:#DDFFDC;
$yellow-CE7:#CE7B2D;
$yellow-FFF:#FFFDDC;


$standard-width: 144rem;


.payment-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6rem;
    align-items: center;
    height: 100vh;
    overflow: scroll;
    img{
        width: 15rem;
    }
}
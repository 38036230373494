@mixin font($color, $family, $size, $weight, $lineHeight) {
    font-family: $family, Arial, Helvetica, sans-serif;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    line-height: $lineHeight;
  }
  @mixin fontWeight( $font-family,$font-weight, $font-style) {
      font-family: $font-family;
      font-weight: $font-weight;
      font-style: $font-style;
    }

    @mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
        $src: null;
      
        $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
        );
      
        $formats: (
          otf: "opentype",
          ttf: "truetype"
        );
      
        @each $ext in $exts {
          $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
          $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
          $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
        }
      
        @font-face {
          font-family: quote($name);
          font-style: $style;
          font-weight: $weight;
          src: $src;
        }
      }


      // mixin for breakpoints for responsive design
      @mixin break-point($point) {
        @if $point == xs {
          @media (max-width: 575px) {
            @content;
          }
        }
        @else if $point == sm {
          @media (min-width: 576px) {
            @content;
          }
        }
        @else if $point == md {
          @media (max-width: 768px) {
            @content;
          }
        }
        @else if $point == lg {
          @media (min-width: 992px) {
            @content;
          }
        }
        @else if $point == xl {
          @media (min-width: 1200px) {
            @content;
          }
        }
      }
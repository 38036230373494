.logo{
    @include break-point(md){
        width: 6rem;
    }
    
}
.header-main{
  .flex button,  button{
        @include break-point(md){
           width: 7.3rem;
           font-size: 0.75rem;
            min-width: unset;
        }
    }

    .after-login{
        .button,button{
            padding: 0.75rem 1rem;
            @include break-point(md){
                font-size: 0.75rem;
                width: 6rem;
                min-width: unset !important;
                .wallet{
                    width: 1.2rem;
                }
                .burger{
                    width: 3rem;
                }
            }
        }
    }
   
}

.dropdown::after{
    display: none !important;
}
.dropdown{
    border-radius: 20px !important;
    width: 8rem !important;
    @include break-point(md){
        width: 6rem !important;
    }
    .open{

    }
}
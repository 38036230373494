@import "../abstracts/mixins";
@import "../abstracts/variables";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.border{
    border-width: 1px;
    border-style: solid;
}
@for $i from 1 through 10 {
    .border-#{$i} {
        border-style: solid;
        border-width: #{$i}px;
    }
}
a:active{
    background-color: transparent !important;
    color: black !important;
}
.pointer{
    cursor: pointer;
}
.white-space-nowrap{
    white-space: nowrap;
}
.cursor-disabled{
    cursor: not-allowed;
}
.gap-sm{
    gap: 0.5rem;
}
.gap-md{
    gap: 1rem;
}
.gap-lg{
    gap: 1.5rem;
}
.gap-xl{
    gap: 2rem;
}
// loop for gap in p
@for $i from 1 through 10 {
    .gap-#{$i} {
        gap: #{$i}px;
    }
}

a,.a{
    font-style: normal;
    text-decoration: none;
    color: $black;
}
::-webkit-scrollbar{
    display: none;
    width: 0;
}
.underline{
    text-decoration: underline;
}

.rounded-sm{
    border-radius: 0.25rem;
}
.rounded-md{
    border-radius: 0.5rem;
}
.rounded-lg{
    border-radius: 1rem;
}
.rounded-xl{
    border-radius: 2rem;
}
.rounded{
    border-radius: 9999px;
}
.text-center{
    text-align: center;
}
@for $i from 1 through 100 {
    .round-#{$i} {
        border-radius: #{$i}px;
    }
    .rounded-#{$i} {
        border-radius: #{$i}px;
    }
}
    @for $i from 0 through 100 {
      .w-#{$i} {
        width: #{$i}rem ;    
      }
      .h-#{$i} {
        height: #{$i}rem;
      }
      .m-#{$i} {
       margin: #{$i}rem;
      }
      .p-#{$i} {
        padding: #{$i}rem;
      }
    }
    .absolute{
        position: absolute;
    }
    .relative{
        position: relative;
    }
    .fixed{
        position: fixed;
    }
    .sticky{
        position: sticky;
    }
    .left{
        text-align: left;
    }
    .right{
        text-align: right;
    }
    .center{
        text-align: center;
    }
    @for $i from 0 through 500 {
      .top-#{$i} {
        top: #{$i}px ;    
      }
      .bottom-#{$i} {
        bottom: #{$i}px;
      }
      .left-#{$i} {
       left: #{$i}px;
      }
      .right-#{$i} {
        right: #{$i}px;
      }
    }
    @for $i from 0 through 100 {
      .w_#{$i} {
        width: #{$i}px ;    
      }
      .h_#{$i} {
        height: #{$i}px;
      }
      .m_#{$i} {
       margin: #{$i}px;
      }
      .p_#{$i} {
        padding: #{$i}px;
      }
    }

    .w-full{
        width: 100%;
    }
    .h-full{
        height: 100%;
    }
    .w-fit{
        width: fit-content;
    }
    .m-auto{
        margin: auto;
    }
    .p-auto{
        padding: auto;
    }
    .w-screen{
        width: 100vw;
    }
    .h-screen{
        height: 100vh;
    }
   
      
    .container-standard{
        max-width: $standard-width;
        width: 100%;
        margin: 0 auto;
    }
    .overflow-scroll{
        overflow: scroll;
    }
    .overflow-hidden{
        overflow: hidden;
    }

.tooltip-inner{
    background-color: $grey-EEE !important;
    font-size: 10px !important;
    color: #7A7878 !important;
    &::before{
        display: none !important;
    }
}
.tooltip-arrow{
    border-right-color: $grey-EEE !important;
}
// Flex classes
.flex {
    display: flex;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-space-between {
    justify-content: space-between;
}   
.justify-space-around {
    justify-content: space-around;
}
.align-start {
    align-items: flex-start;
}
.align-end {
    align-items: flex-end;
}
.align-center {
    align-items: center;
}
.align-baseline {
    align-items: baseline;
}
.align-stretch {
    align-items: stretch;
}
.align-content-start {
    align-content: flex-start;
}
.align-content-end {
    align-content: flex-end;
}
.align-content-center {
    align-content: center;
}
.align-content-space-between {
    align-content: space-between;
}
.align-content-space-around {
    align-content: space-around;
}
.align-content-stretch {
    align-content: stretch;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.self-baseline {
    align-self: baseline;
}
.self-stretch {
    align-self: stretch;
}

   


.footer{
padding:0 100px;
@include break-point(md){
    .f-s-15{
        font-size: 7px;
    }
    padding:2px 10px !important;
    .left{
        gap: 2px;
    }
}
.left{
    align-items: center;
}
}
@import '../abstracts/variables';

$colors:(
    blue-211:$blue-211,
    purple-E3D: $purple-E3D,
    grey-F4F: $grey-F4F,
    grey-D9D: $grey-D9D,
    grey-D8D: $grey-D8D,
    grey-808:$grey-808,
    grey-EEE:$grey-EEE,
    grey-F9F: $grey-F9F,
    white: $white,
    black: $black,
    grey: $grey,
    red: $red,
    red-CE2: $red-CE2,
    red-FFE: $red-FFE,
    green-0C8: $green-0C8,
    green-DDF: $green-DDF,
    yellow-CE7: $yellow-CE7,
    yellow-FFF: $yellow-FFF,
    transparent: rgba(0,0,0,0),
);

// for loop to add bg-color color and border-color 
@each $color, $value in $colors {
    .bg-#{$color} {
        background-color: $value;
    }
    .border-#{$color} {
        border-color: $value;
    }
    .#{$color}{
        color: $value;
    }
}
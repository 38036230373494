@import '../abstracts/abstracts-dir';

@include font-face(Montserrat-Regular, "../../fonts/Montserrat/Montserrat-Regular", 400, normal, ttf);
@include font-face(Montserrat-Medium, "../../fonts/Montserrat/Montserrat-Medium", 500, normal, ttf);
@include font-face(Montserrat-SemiBold, "../../fonts/Montserrat/Montserrat-SemiBold", 600, normal, ttf);
@include font-face(Montserrat-Bold, "../../fonts/Montserrat/Montserrat-Bold", 700, normal, ttf);


.montserrat-regular {
    @include fontWeight(Montserrat-Regular, 400, normal);
}
.montserrat-medium {
    @include fontWeight(Montserrat-Medium, 500, normal);
}
.montserrat-semibold {
    @include fontWeight(Montserrat-SemiBold, 600, normal);
}
.montserrat-bold {
    @include fontWeight(Montserrat-Bold, 700, normal);
}
*{
    @include font(black,Montserrat-Regular,16px,400,inherit);
}


@for $i from 1 through 100 {
    .f-s-#{$i} {
        font-size: #{$i}px;
    }
}

.bookings-main-container{
    .tabs {
        position: relative;
        display: flex;
        gap: 20px; /* Adjust the gap as needed */
      }
      
      .tab {
        cursor: pointer;
        position: relative;
        padding: 10px;
        color: $grey-808;
      }
      
      .underline {
        position: absolute;
        bottom: -2px;
        height: 0.312rem;
        background: $blue-211;
        transition: width 0.3s, left 0.3s;
      }
      
      .tab.active {
        color: $blue-211; 
      }
      
    .bookings-header{
        margin:2.5rem 100px;
        @include break-point(md){
            margin:  2.5rem 0;
            padding:0 1rem ;
            margin-bottom: 1rem;
        }
    }
    .bookings-main{
        overflow: auto;
        .main-head-container{
            width: 100vw;
            height: 9.3rem;
            background: linear-gradient(90deg, $purple-E3D 0%, $blue-211 100%);
            .head-user-info{
                padding:0 100px;
                @include break-point(md){
                    padding: 0 1rem;
                }
                .edit-logo{
                    margin-left: 0.6735rem;
                }
            }
        }

        .head-upcoming-main{
            margin:0 100px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
            transform: translateY(-1.5rem);
            padding: 2.5rem;
            @include break-point(md){
                margin: 0 1rem;
                padding: 1rem;
            }
            .upcoming-cards{
                margin: 2rem 0;
                overflow: scroll;
                @include break-point(md){
                   .f-s-12{
                       font-size: 10px;
                   }
                   .f-s-14{
                       font-size: 12px;
                   }
                     .f-s-15{
                          font-size: 13px;
                     }
                     .f-s-16{
                          font-size: 14px;
                     }

                }
            }
        }
    }
   
}

.booking-card-main{
    .main-content{
        max-width: 22rem;
    }
    .tips{
        @include break-point(md){
            font-size: 10px;
        }
    }
   @include break-point(md){
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem  !important;
   }
    .content-wrapper{
        @include break-point(md){
           flex-direction: column;
        }
    }
}

.nothing-show-img{
    @include break-point(md){
        width: 70%;
        height: 10rem;
    }
}
textarea{
    width: 80%;
    border: none;
    border-radius: 10px;
    padding: 0.75rem 1rem;
}

textarea:focus{
    outline: none;
    border: none;
}

.modal-box{
    @include break-point(md){
        width: 100%;
        max-width: 90% !important;
        max-height: 90% !important;
    }
}

@include break-point(md){
    .home-login-main, .home-personal-details, .home-serivce-details,.bookings-cancel-booking,.home-change-date{
        width: 100% !important;
    }
    .bookings-change-date{
        .date-slot-container{
            flex-direction: column;
            box-shadow: none !important;
            border: none !important;
            padding: 0 !important;
        }
    }
    .home-serivce-details{
        img{
            width: 100%;
            height: 11rem;
        }
        .flex{
            flex-direction: column-reverse;
        }
        .f-s-14{
            font-size: 12px;
        }
        .f-s-15{
            font-size: 13px;
        }
        .f-s-12{
            font-size: 10px;
        }
    }
    .home-login-main{
        .f-s-14{
            font-size: 12px;
        }
        .f-s-15{
            font-size: 13px;
        }
        .f-s-12{
            font-size: 10px;
        }
    }
}


.home-serivce-details{
    .container{
        padding: 0;
    }
    .service-img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}